import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core';
import {
	faGasPump,
	faToggleOn,
	faToggleOff,
	faSearchPlus,
	faCrosshairs,
} from '@fortawesome/free-solid-svg-icons';
import { Fuel } from '../models/fuel/fuel';
import { GasStation } from '../../../../server/src/models/GasStation';
import { GasStationsService } from '../services/gas-stations.service';
import { Helper } from '../helper';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import { MapManagerComponent } from './map-manager/map-manager.component';
import { LogHelper, LogType } from '../logHelper';
import { LatLng } from 'leaflet';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss', './app.component.gasoline.frame.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
	@ViewChild(MapManagerComponent) mapManager: MapManagerComponent;

	public title = 'Gas price checker';
	public status = true;
	public radiusValue = 5;
	public radiusValueMax = 35;
	public showTooManyGasStationWarning = false;
	public iconGasPump = faGasPump;
	public iconCrosshairs = faCrosshairs;
	public radiausIcon = faSearchPlus;
	public iconToggleOn = faToggleOn;
	public iconToggleOff = faToggleOff;
	public openFuelsFrame = false;
	public openGasStationsFrame = false;
	public openMapRadiusFrame = false;
	public fuelsList = new Array<Fuel>();
	public gasStationsList = new Array<GasStation>();
	public gasStationsNearByList = new Array<GasStation>();
	public gasStationsNearByFilteredByFuelList = new Array<GasStation>();
	//
	private mGasStationsService: GasStationsService;
	private mUserLat: number;
	private mUserLng: number;
	private mLoadingData: boolean;
	private mLoadDataFailedCount: number;
	private mTranslateService: TranslateService;
	//

	constructor(
		gasStationsService: GasStationsService,
		translateService: TranslateService
	) {
		this.mGasStationsService = gasStationsService;
		this.mTranslateService = translateService;
		this.mTranslateService.addLangs(['en', 'fr']);
		this.mTranslateService.setDefaultLang('fr');
		this.gasStationsNearByFilteredByFuelList = [];
		this.fuelsList.push(
			new Fuel('E10', true),
			new Fuel('E85', true),
			new Fuel('Gazole', true),
			new Fuel('GPLc', true),
			new Fuel('SP95', true),
			new Fuel('SP98', true)
		);
		this.mUserLat = null;
		this.mUserLng = null;
		this.mLoadingData = false;
		this.mLoadDataFailedCount = 0;
	}

	ngAfterViewInit(): void {
		const menuContainer = document.getElementsByClassName('container-menu');
		let svgMenuIcons: HTMLCollectionOf<Element>;
		let cssContent: string = null;
		//
		for (let i = 0; i < document.styleSheets.length; ++i) {
			if (cssContent != null) {
				break;
			}
			const styleSheet = document.styleSheets.item(i);
			if (styleSheet) {
				try {
					for (let ii = 0; ii < styleSheet.cssRules.length; ++ii) {
						const rule = styleSheet.cssRules.item(ii);
						if (
							rule &&
							rule.cssText.indexOf('.svg-inline--fa-overload') >
								-1
						) {
							const regexp = new RegExp(/.*\{(.*)\}/);
							const result = regexp.exec(rule.cssText);
							if (result.length > 1) {
								cssContent = result[1].trim();
								break;
							}
						}
					}
				} catch (Err) {}
			}
		}
		if (
			cssContent != null &&
			menuContainer != null &&
			menuContainer.length > 0
		) {
			svgMenuIcons = menuContainer
				.item(0)
				.getElementsByClassName('svg-inline--fa');
			if (svgMenuIcons?.length > 0) {
				for (let i = 0; i < svgMenuIcons.length; ++i) {
					const style = window.getComputedStyle(svgMenuIcons.item(i));
					(<HTMLElement>svgMenuIcons.item(i)).setAttribute(
						'style',
						cssContent
					);
				}
			}
		}
	}

	ngOnInit(): void {
		this.initHooks();
		this.setLanguage();
		const frames = document.getElementsByClassName('options-frame');
		if (frames && frames.length > 0) {
			for (let i = 0; i < frames.length; ++i) {
				frames
					.item(i)
					.addEventListener(
						'animationend',
						(event: AnimationEvent) => {
							if (event.animationName.indexOf('hide') >= 0) {
								(<HTMLElement>event.currentTarget).className +=
									'display: none;';
							}
						}
					);
			}
		}
	}

	private initHooks(): void {
		window.addEventListener('onAndroidPositionChange', (event: Event) => {
			LogHelper.log('Got new position from android device', LogType.INFO);
			if (event['data'] && event['data'].location) {
				try {
					LogHelper.log(
						`Latitude: ${event['data'].location.latitude} | longitude: ${event['data'].location.longitude}`,
						LogType.INFO
					);
					this.onPositionChanged(
						event['data'].location.latitude,
						event['data'].location.longitude
					);
				} catch (err) {}
			}
		});
		window.addEventListener('onChangeLanguage', (event: Event) => {
			LogHelper.log('Change language requested', LogType.INFO);
			if (event['data'] && event['data'].language) {
				try {
					LogHelper.log(
						`New language: ${event['data'].language}`,
						LogType.INFO
					);
					this.mTranslateService.use(event['data'].language);
				} catch (err) {}
			}
		});
	}

	private setLanguage(): void {
		try {
			let lang: string;
			if (window['android']) {
				try {
					lang = window['android'].getLanguage();
					this.mTranslateService.use(lang);
				} catch (Err) {}
			} else {
				lang = this.mTranslateService.getBrowserLang();
				this.mTranslateService.use(lang.match(/en|fr/) ? lang : 'en');
			}
		} catch (err) {}
	}

	onMapReady(ready: boolean): void {
		if (ready) {
			if (window['android']) {
				try {
					const location = JSON.parse(
						window['android'].getLastPosition()
					);
					if (
						isNaN(location.latitude) == false &&
						isNaN(location.longitude) == false
					) {
						this.mUserLat = location.latitude;
						this.mUserLng = location.longitude;
					}
					this.loadData(this.mUserLat, this.mUserLng);
				} catch (err) {
					LogHelper.log(
						`Couldn't get position from android device: ${err}`,
						LogType.WARNING
					);
					this.loadData(this.mUserLat, this.mUserLng);
				}
			} else if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(
					(position: GeolocationPosition) => {
						this.mUserLat = position.coords.latitude;
						this.mUserLng = position.coords.longitude;
						this.loadData(this.mUserLat, this.mUserLng);
					},
					(error: GeolocationPositionError) => {
						LogHelper.log(
							`Couldn't get position from your navigator: ${error.message}`,
							LogType.WARNING
						);
						this.loadData(this.mUserLat, this.mUserLng);
					}
				);
			}
		}
	}

	loadData(latitude: number, longitude: number): void {
		if (
			this.mLoadingData == false &&
			latitude != null &&
			longitude != null
		) {
			this.mLoadingData = true;
			this.mGasStationsService
				.getGasStations(latitude, longitude, this.radiusValueMax)
				.subscribe(
					(data) => {
						this.gasStationsList = [];
						this.gasStationsNearByList = [];

						data = data.sort((a: GasStation, b: GasStation) => {
							const distA = Helper.getDistance(
								this.mUserLat,
								this.mUserLng,
								a.latitude,
								a.longitude
							);
							const distB = Helper.getDistance(
								this.mUserLat,
								this.mUserLng,
								b.latitude,
								b.longitude
							);
							//
							return distA - distB;
						});
						this.gasStationsList = <Array<GasStation>>data;
						LogHelper.log(
							`Got list of ${this.gasStationsList.length} gas stations`,
							LogType.INFO
						);
						this.updateGasStationsNearBy();
						this.mLoadingData = false;
						this.mLoadDataFailedCount = 0;
					},
					(error) => {
						this.mLoadingData = false;
						if (this.mLoadDataFailedCount < 5) {
							++this.mLoadDataFailedCount;
							setTimeout(() => {
								this.loadData(latitude, longitude);
							}, 5000);
						}
						LogHelper.log(
							`Failed to get gas stations list: ${JSON.stringify(
								error
							)}`,
							LogType.ERROR
						);
					}
				);
		} else {
			this.mapManager.flyTo(46.66147974130632, 2.5013062094518816, 6);
		}
	}

	private updateGasStationsNearBy(): void {
		this.gasStationsNearByList = [];
		for (const gasStation of this.gasStationsList) {
			if (
				Helper.isInsideAPerimeter(
					this.mUserLat,
					this.mUserLng,
					gasStation.latitude,
					gasStation.longitude,
					this.radiusValue
				)
			) {
				this.gasStationsNearByList.push(gasStation);
			}
		}
		this.updateGasStationsNearByFilteredByGasType();
		this.updateMarkers(this.radiusValue);
	}

	private updateGasStationsNearByFilteredByGasType(): void {
		let count = 0;
		this.gasStationsNearByFilteredByFuelList = [];
		this.showTooManyGasStationWarning = false;
		//
		for (const gasStation of this.gasStationsNearByList) {
			count = 0;
			for (const price of gasStation.prices) {
				for (const fuel of this.fuelsList) {
					if (fuel.name == price.name) {
						if (fuel.isEnable) {
							++count;
						}
						break;
					}
				}
			}
			if (count > 0) {
				this.gasStationsNearByFilteredByFuelList.push(gasStation);
				if (this.gasStationsNearByFilteredByFuelList.length >= 50) {
					setTimeout(() => {
						this.showTooManyGasStationWarning = true;
						this.radiusValue = Math.ceil(
							Helper.getDistance(
								this.mUserLat,
								this.mUserLng,
								gasStation.latitude,
								gasStation.longitude
							)
						);
					}, 150);
					break;
				}
			}
		}
	}

	private openCloseMenu(selectedItem: string | HTMLElement): void {
		switch (selectedItem) {
			case 'button-fuels':
				this.openGasStationsFrame = false;
				this.openMapRadiusFrame = false;
				this.openFuelsFrame = !this.openFuelsFrame;
				break;
			case 'button-services':
				break;
			case 'button-gas-stations':
				this.openFuelsFrame = false;
				this.openMapRadiusFrame = false;
				this.openGasStationsFrame = !this.openGasStationsFrame;
				break;
			case 'button-distance':
				this.openFuelsFrame = false;
				this.openGasStationsFrame = false;
				this.openMapRadiusFrame = !this.openMapRadiusFrame;
				break;
			default:
				this.openFuelsFrame = false;
				this.openGasStationsFrame = false;
				this.openMapRadiusFrame = false;
				break;
		}
	}

	clickOnFuelButtons(evt: any, fuel: Fuel) {
		fuel.isEnable = !fuel.isEnable;
		this.updateGasStationsNearByFilteredByGasType();
		this.updateMarkers(this.radiusValue);
	}

	onLocalizeGasStation(evt: any, gasStation: GasStation) {
		LogHelper.log(`localize: ${gasStation.address}`, LogType.INFO);
		this.openCloseMenu('');
		this.mapManager.flyTo(gasStation.latitude, gasStation.longitude);
		this.mapManager.resetSelectedMarkers();
		this.mapManager.selectMarker(gasStation.latitude, gasStation.longitude);
		if (window['android'] && window['android'].copyToClipboard) {
			window['android'].copyToClipboard(
				`${gasStation.address}, ${gasStation.postalCode} ${gasStation.city}`
			);
		}
	}

	clickOnMenuButtons(evt: MouseEvent) {
		if (evt.currentTarget instanceof HTMLElement) {
			const target = <HTMLElement>evt.currentTarget;
			this.openCloseMenu(target.id);
		}
	}

	public onPositionChanged(lat: number, lng: number): void {
		this.mUserLat = lat;
		this.mUserLng = lng;
		this.loadData(lat, lng);
	}

	public onMarkerClicked(id: string): void {
		if (!this.openGasStationsFrame) {
			this.openCloseMenu('button-gas-stations');
			setTimeout(() => {
				const list = document.getElementById('gasStationList');
				for (let i = 0; i < list.children.length; ++i) {
					const item = <HTMLElement>list.children.item(i);
					if (item.id == id) {
						const container = item.children.item(0);
						container.className += ' selected';
						container.addEventListener(
							'animationend',
							(evt: any) => {
								evt.currentTarget.className =
									evt.currentTarget.className.replace(
										' selected',
										''
									);
							}
						);
						const itemY = item.offsetTop - list.offsetTop;
						list.scrollTo({
							top: itemY,
						});
						break;
					}
				}
			}, 250);
		}
	}

	public onUserChangePosition(latlng: LatLng): void {
		this.onPositionChanged(latlng.lat, latlng.lng);
		if (window['android']) {
			try {
				window['android'].onUserchangePosition(latlng.lat, latlng.lng);
			} catch (Err) {}
		}
	}

	onMapClicked(): void {
		this.openCloseMenu('');
	}

	updateMarkers(radius: number): void {
		let distance: number;
		//
		this.mapManager.resetMarkersList();
		let i = 1;
		for (const gasStation of this.gasStationsNearByFilteredByFuelList) {
			distance = Helper.getDistance(
				this.mUserLat,
				this.mUserLng,
				gasStation.latitude,
				gasStation.longitude
			);
			this.mapManager.addMarker(
				gasStation.latitude,
				gasStation.longitude,
				distance,
				gasStation.id,
				i++,
				gasStation.brand
			);
		}
		this.mapManager.centerCamera(this.mUserLat, this.mUserLng);
		this.mapManager.drawRadius(this.mUserLat, this.mUserLng, radius);
	}

	updateRadiusThumb(value): string {
		return value + 'km';
	}

	onRadiusChanged(event: MatSliderChange) {
		this.radiusValue = event.value;
		if (this.mUserLat != null && this.mUserLng != null) {
			this.updateGasStationsNearBy();
		}
	}
}
