<div class="container-map">
	<app-map-manager
		(alertParentOnMapReady)="onMapReady($event)"
		(alertParentOnMarkerClick)="onMarkerClicked($event)"
		(alertParentOnMapClick)="onMapClicked()"
		(alertParentOnUserChangePosition)="onUserChangePosition($event)"
	></app-map-manager>
</div>
<div class="container-menu">
	<ul>
		<li
			id="button-distance"
			class="menu-items"
			[class.selected]="openMapRadiusFrame == true"
			(click)="clickOnMenuButtons($event)"
		>
			<div class="menu-item-container">
				<fa-icon [icon]="iconCrosshairs"></fa-icon>
			</div>
			<p>{{ "app.menu.perimeter" | translate }}</p>
		</li>
		<li
			id="button-gas-stations"
			class="menu-items"
			[class.selected]="openGasStationsFrame == true"
			(click)="clickOnMenuButtons($event)"
		>
			<div class="menu-item-container">
				<fa-icon [icon]="iconGasPump"></fa-icon>
			</div>
			<p>{{ "app.menu.gas_stations" | translate }}</p>
		</li>
		<li
			id="button-fuels"
			class="menu-items"
			[class.selected]="openFuelsFrame == true"
			(click)="clickOnMenuButtons($event)"
		>
			<div class="menu-item-container">
				<fa-icon [icon]="iconGasPump"></fa-icon>
			</div>
			<p>{{ "app.menu.fuels" | translate }}</p>
		</li>
	</ul>
</div>
<!-- FUEL FRAME -->
<div
	id="fuels-frame"
	class="options-frame"
	[class.show]="openFuelsFrame == true"
	[class.hide]="openFuelsFrame == false"
>
	<div class="titleContainer">
		<p>{{ "app.frames_title.fuels" | translate }}</p>
	</div>
	<ul>
		<li
			*ngFor="let fuel of fuelsList"
			(click)="clickOnFuelButtons($event, fuel)"
		>
			<div class="fuelTypeContainer">
				<fa-icon [icon]="iconGasPump"></fa-icon>
				<p>{{ fuel.name }}</p>
			</div>
			<div class="fuelSelectorContainer">
				<ng-template [ngIf]="fuel.isEnable" [ngIfElse]="isDisable">
					<fa-icon [icon]="iconToggleOn"></fa-icon>
				</ng-template>
				<ng-template #isDisable>
					<fa-icon [icon]="iconToggleOff"></fa-icon>
				</ng-template>
			</div>
		</li>
	</ul>
	<div class="gradient"></div>
</div>

<!-- GAS STATION FRAME -->
<div
	id="gas-stations-frame"
	class="options-frame"
	[class.show]="openGasStationsFrame == true"
	[class.hide]="openGasStationsFrame == false"
>
	<div class="titleContainer">
		<p>{{ "app.frames_title.gas_stations" | translate }}</p>
	</div>

	<ul id="gasStationList">
		<app-gas-station-item
			(alertParentOnLocalizationButtonClick)="
				onLocalizeGasStation($event, gasStation)
			"
			id="{{ gasStation.id }}"
			*ngFor="
				let gasStation of gasStationsNearByFilteredByFuelList;
				let i = index
			"
			[gasStation]="gasStation"
			[userLat]="mUserLat"
			[userLng]="mUserLng"
			[rank]="i + 1"
			[count]="gasStationsNearByFilteredByFuelList.length"
			[fuelFilters]="fuelsList"
		>
		</app-gas-station-item>
	</ul>

	<div class="gradient"></div>
</div>

<div
	id="map-radius"
	class="options-frame"
	[class.show]="openMapRadiusFrame == true"
	[class.hide]="openMapRadiusFrame == false"
>
	<div class="titleContainer">
		<p>{{ "app.frames_title.perimeter" | translate }}</p>
	</div>
	<mat-slider
		min="1"
		max="{{ radiusValueMax }}"
		step="1"
		value="{{ radiusValue }}"
		thumbLabel="true"
		(change)="onRadiusChanged($event)"
		[displayWith]="updateRadiusThumb"
	></mat-slider>

	<div
		class="warning-container"
		[class.show]="showTooManyGasStationWarning == true"
	>
		<p>
			{{ "app.messages.too_many_stations" | translate }}
		</p>
	</div>

	<div class="gradient"></div>
</div>
