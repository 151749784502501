import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { Helper } from 'src/app/helper';
import { GasStation, Price } from '../../../../../server/src/models/GasStation';
import { faMapMarkedAlt } from '@fortawesome/free-solid-svg-icons';
import { Fuel } from 'src/app/models/fuel/fuel';
import { faGasPump } from '@fortawesome/free-solid-svg-icons';
import { LogHelper, LogType } from 'src/app/logHelper';

@Component({
  selector: 'app-gas-station-item',
  templateUrl: './gas-station-item.component.html',
  styleUrls: ['./gas-station-item.component.scss', './gas-station-item-details.component.scss']
})
export class GasStationItemComponent implements AfterViewInit {

  @Input() gasStation: GasStation;
  @Input() userLat: number;
  @Input() userLng: number;
  @Input() rank: number;
  @Input() count: number;
  @Input() fuelFilters: Array<Fuel>;
  //
  @Output() alertParentOnLocalizationButtonClick: EventEmitter<GasStation> = new EventEmitter<GasStation>();

  iconLocalize = faMapMarkedAlt;
  public iconGasPump = faGasPump;
  @ViewChild('gasStationLogoIcon') gasStationLogoIcon;
  @ViewChild('gasStationLogoImg') gasStationLogoImg;

  public showLogoIcon: boolean;
  public showLogoImg: boolean;

  constructor() {
    this.showLogoIcon = false;
    this.showLogoImg = false;
  }

  ngAfterViewInit() {
    setTimeout(() => {
      const brand = this.gasStation.brand.toLowerCase().replace(/\s/g, '').replace(/[éè]/g, 'e');
      if (brand.length == 0) {
        this.showLogoIcon = true;
        this.showLogoImg = false;
      }
      else {
        this.showLogoIcon = false;
        this.showLogoImg = true;
        this.gasStationLogoImg.nativeElement.src = `../../assets/gas-station-icons/${brand}.png`;
      }
    }, 0);
  }

  getDistance(): string {
    let distance: number;
    //
    distance = Helper.getDistance(this.userLat, this.userLng, this.gasStation.latitude, this.gasStation.longitude);
    //
    return distance.toFixed(1);
  }

  public getPricesList(): Array<Price> {
    const pricesFiltered = new Array<Price>();
    //
    for (const price of this.gasStation.prices) {
      for (const fuel of this.fuelFilters) {
        if (fuel.name == price.name) {
          if (fuel.isEnable) {
            pricesFiltered.push(price);
          }
          break;
        }
      }
    }
    //
    return pricesFiltered;
  }

  public isAnteriorTo5Days(price: Price): boolean {
    let isAnterior = false;
    const now = new Date();
    const fiveDays = 5 * 24 * 60 * 60 * 1000;
    const timeRef = now.getTime() - fiveDays;
    const priceUpdate = new Date(price.update);
    //
    if (priceUpdate.getTime() < timeRef) {
      isAnterior = true;
    }
    //
    return isAnterior;
  }

  public getLastUpdate(price: Price): string {
    let lastUpdate: string;
    const date = new Date(price.update);
    //
    lastUpdate = `${date.getFullYear().toString()}-`;
    lastUpdate += date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}-` : `${date.getMonth() + 1}-`;
    lastUpdate += date.getDate() < 10 ? `0${date.getDate()}` : `${date.getDate()}`;
    //
    return lastUpdate;
  }

  public onDetailsButtonClicked(event: Event): void {
    const list = document.getElementsByClassName('container details');
    const parent = (<HTMLElement>event.target).parentElement;
    //
    for (let i = 0; i < list.length; ++i) {
      if (list.item(i) != parent) {
        list.item(i).className = list.item(i).className.replace('details', '').trimEnd();
      }
    }
    if (parent) {
      if (parent.className.indexOf('details') != -1) {
        parent.className = parent.className.replace('details', '').trimEnd();
      }
      else {
        parent.className += ' details';
      }
    }
  }

  clickOnLocalize(evt: any, gasStation: GasStation): void {
    LogHelper.log(`click on localize gas station: ${gasStation.address}`, LogType.DEBUG);
    this.alertParentOnLocalizationButtonClick.emit(gasStation);
  }

}
