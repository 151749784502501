<div class="container">
	<div class="side-container">
		<div class="rank-container">
			<p>{{ rank }}/{{ count }}</p>
		</div>
		<div class="logo-container">
			<fa-icon
				[icon]="iconGasPump"
				#gasStationLogoIcon
				[class.show]="showLogoIcon"
			></fa-icon>
			<img #gasStationLogoImg [class.show]="showLogoImg" />
		</div>
		<div class="type-container">
			<p class="distance">{{ getDistance() }}km</p>
		</div>
	</div>
	<div class="details-container">
		<div class="info-container">
			<p class="address">
				{{ gasStation.address }}, {{ gasStation.postalCode }}
				{{ gasStation.city }}
			</p>
			<div
				class="localize-container"
				(click)="clickOnLocalize($event, gasStation)"
			>
				<fa-icon [icon]="iconLocalize"></fa-icon>
			</div>
		</div>
		<div class="prices-container">
			<ul class="items-container" layout="row">
				<li class="item" *ngFor="let price of getPricesList()">
					<div class="gas-name-container">
						<div class="circle-bg {{ price.name.toLowerCase() }}">
							<p class="gas-name">{{ price.name }}</p>
						</div>
					</div>
					<div class="gas-price-container">
						<p class="gas-price">{{ price.price }}€</p>
					</div>
					<div class="details-container">
						<ul>
							<li
								class="details-icons"
								[class.hide]="isAnteriorTo5Days(price) == false"
							>
								<img
									class="icons"
									src="../../../assets/warning-icon.png"
								/>
							</li>
						</ul>
						<div class="last-update-container">
							<p class="title">
								{{ "app.items.last_update" | translate }}
							</p>
							<p class="last-update">
								{{ getLastUpdate(price) }}
							</p>
						</div>
					</div>
				</li>
			</ul>
		</div>
	</div>
	<div class="expand-button" (click)="onDetailsButtonClicked($event)"></div>
</div>
