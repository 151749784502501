import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LogHelper, LogType } from '../logHelper';

@Injectable({
	providedIn: 'root',
})
export class GasStationsService {
	public static readonly Uri =
		'https://stations-services-infos-server.sekebusiness.com/getGasStationsFromCoordinates';
	//
	private mHttp: HttpClient;

	constructor(http: HttpClient) {
		this.mHttp = http;
	}

	public getGasStations(
		latitude: number,
		longitude: number,
		radius: number
	): Observable<any> {
		const params = new HttpParams();
		const headers = new HttpHeaders();
		const data = {
			checkPoint: {
				longitude: longitude,
				latitude: latitude,
			},
			radiusKm: radius,
		};
		headers.append('Content-Type', 'application/json');
		LogHelper.log(
			`Get stations from '${
				GasStationsService.Uri
			}' with params '${JSON.stringify(data)}'`,
			LogType.DEBUG
		);
		return this.mHttp.post(GasStationsService.Uri, data, {
			params,
			headers,
		});
	}
}
