import {
	Component,
	OnInit,
	Output,
	EventEmitter,
	AfterViewInit,
} from '@angular/core';
import {
	Map as LMap,
	LatLng,
	tileLayer,
	Marker,
	Icon,
	IconOptions,
	MarkerOptions,
	TileLayerOptions,
	FeatureGroup,
	LatLngBounds,
	Circle,
	CircleMarkerOptions,
	Tooltip,
	TooltipOptions,
	Point,
	imageOverlay,
	CircleOptions,
} from 'leaflet';
import { Observable } from 'rxjs';

@Component({
	selector: 'app-map-manager',
	templateUrl: './map-manager.component.html',
	styleUrls: [
		//'../../../assets/leaflet.css',
		'./map-manager.component.scss',
	],
})
export class MapManagerComponent implements OnInit, AfterViewInit {
	@Output() alertParentOnMapReady: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	@Output() alertParentOnMarkerClick: EventEmitter<number> =
		new EventEmitter<number>();
	@Output() alertParentOnMapClick: EventEmitter<void> =
		new EventEmitter<void>();
	@Output() alertParentOnUserChangePosition: EventEmitter<LatLng> =
		new EventEmitter<LatLng>();

	private mMarkersCoodonates: Array<Marker> = new Array<Marker>();
	private mMarkerYouAreHere: Marker;
	private mMap: LMap;
	private mMarkerIconList: Map<string, Icon>;
	private mMarkerIcon: Icon;
	private mMarkerIconSelected: Icon;
	private mMarkerYouAreHereIcon: Icon;
	private mRadiusCircled: Circle;
	private mMouseDownTimeout: NodeJS.Timeout;

	ngOnInit(): void {
		this.mMarkerIconList = new Map<string, Icon>();
		this.mMarkerIcon = new Icon(<IconOptions>{
			iconUrl: '../../assets/images/marker-icon.png',
			iconSize: [25, 41],
		});
		this.mMarkerIconSelected = new Icon(<IconOptions>{
			iconUrl: '../../assets/images/marker-icon-selected.png',
			iconSize: [25, 41],
		});
		this.mMarkerYouAreHereIcon = new Icon(<IconOptions>{
			iconUrl: '../../assets/images/you_here.png',
			iconSize: [25, 45],
		});
		this.mMouseDownTimeout = null;
	}

	ngAfterViewInit(): void {
		let touchDownEvents = ['mousedown', 'touchstart'];
		let touchUpEvents = ['mouseup', 'touchend'];
		let touchCancelEvents = ['mouseout', 'touchcancel'];
		let touchMoveEvents = ['mousemove', 'touchmove', 'moveend'];
		this.mMap = new LMap('map');

		this.mMap.setView(new LatLng(44.383724, 4.989187));
		this.mMap.setZoom(4);
		//this.mMap.on('zoomend', this.onZoomEnd, this);
		this.mMap.on('click', this.onMapClicked, this);
		if (window['android']) {
			touchDownEvents.push('contextmenu');
		} else {
			this.mMap.on(
				'contextmenu',
				(event: any) => {
					if (event['originalEvent']) {
						event['originalEvent'].preventDefault();
					}
				},
				this
			);
		}
		//
		for (let event of touchDownEvents) {
			this.mMap.on(event, this.onTouchStart, this);
		}
		for (let event of touchUpEvents) {
			this.mMap.on(event, this.onTouchEnd, this);
		}
		for (let event of touchCancelEvents) {
			this.mMap.on(event, this.onTouchEnd, this);
		}
		for (let event of touchMoveEvents) {
			this.mMap.on(event, this.onTouchEnd, this);
		}
		//let layerUrl = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';
		const layerUrl =
			'https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}';
		tileLayer(layerUrl, <TileLayerOptions>{
			attribution:
				'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
			minZoom: 3,
			maxZoom: 15,
			errorTileUrl: "Oups, couldn't load this area :(",
			crossOrigin: true,
			id: 'mapbox/streets-v11',
			tileSize: 512,
			zoomOffset: -1,
			accessToken:
				'pk.eyJ1Ijoic2thcHBzODYiLCJhIjoiY2tsMjZwZ3FnMDV6dDJvcDF2bWRxYzZ5dSJ9.hHl2t66cbG0FY0k4bneNzQ',
		}).addTo(this.mMap);
		this.alertParentOnMapReady.emit(true);
	}

	public resetMarkersList(reset: boolean = true): void {
		for (let marker of this.mMarkersCoodonates) {
			marker.removeFrom(this.mMap);
			let tooltip = marker.getTooltip();
			tooltip.closeTooltip();
			tooltip.clearAllEventListeners();
			tooltip.unbindTooltip();
			tooltip = null;
			marker.clearAllEventListeners();
			marker = null;
		}
		if (reset) {
			this.mMarkersCoodonates = [];
		}
	}

	private getIconForThisBrand(brand: string): Icon {
		return null;
	}

	public addMarker(
		lat: number,
		lng: number,
		distance: number,
		id: number,
		index: number,
		brand: string
	): void {
		let newMarker: Marker;
		let title: string;
		let icon: Icon;
		//
		title = `${index} - ${distance.toFixed(1)}km`;
		brand = brand.toLowerCase().replace(/\s/g, '').replace(/[éè]/g, 'e');
		if (this.mMarkerIconList.has(brand)) {
			icon = this.mMarkerIconList.get(brand);
		} else {
			if (brand.length == 0) {
				icon = new Icon({
					iconUrl: '../../assets/images/marker-icon.png',
					iconSize: [25, 41],
					iconAnchor: [12, 110],
				});
			} else {
				icon = new Icon({
					iconUrl: `../../assets/gas-station-icons/${brand}.png`,
					iconSize: [50, 50],
					iconAnchor: [25, 120],
					/*shadowUrl: '../../assets/gas-station-icons/logo_bg.png',
          shadowSize: [80, 80]*/
				});
			}
			this.mMarkerIconList.set(brand, icon);
		}
		newMarker = new Marker(new LatLng(lat, lng), <MarkerOptions>{
			icon: icon,
			title,
			alt: id.toString(),
		});
		newMarker['data'] = {
			lat: lat,
			lng: lng,
			distance: distance,
			id: id,
			index: index,
			brand: brand,
		};
		newMarker['index'] = index;
		// @ts-ignore
		newMarker.on('click', this.onMarkerCliked, this);
		newMarker.addTo(this.mMap);
		newMarker
			.bindTooltip(title, <TooltipOptions>{
				opacity: 0.8,
				permanent: true,
				direction: 'top',
				offset: [0, -25],
				interactive: true,
			})
			.openTooltip();
		this.mMarkersCoodonates.push(newMarker);
	}

	public centerCamera(lat: number, lng: number): void {
		let latlng: LatLng;
		let featureGroup: FeatureGroup;
		let bounds: LatLngBounds;
		//
		latlng = new LatLng(lat, lng);
		bounds = latlng.toBounds(1000);
		if (this.mMarkersCoodonates.length > 0) {
			featureGroup = new FeatureGroup(this.mMarkersCoodonates);
			bounds.extend(featureGroup.getBounds());
		}
		this.mMap.fitBounds(bounds);
	}

	public flyTo(lat: number, lng: number, zoom: number = 14): void {
		this.mMap.flyTo(new LatLng(lat, lng), zoom, {
			animate: true,
			duration: 0.3,
		});
	}

	public selectMarker(lat: number, lng: number): void {
		for (const marker of this.mMarkersCoodonates) {
			const coords = marker.getLatLng();
			if (coords.lat == lat && coords.lng == lng) {
				marker.setIcon(this.mMarkerIconSelected);
				marker.getTooltip().getElement().className += ' selected';
				break;
			}
		}
	}

	public resetSelectedMarkers(
		lat?: number | null,
		lng?: number | null
	): void {
		let brand: string;
		//
		for (const marker of this.mMarkersCoodonates) {
			brand = marker['data'].brand;
			if (lat == null || lng == null) {
				marker.setIcon(
					this.mMarkerIconList.has(brand)
						? this.mMarkerIconList.get(brand)
						: this.mMarkerIcon
				);
				marker.getTooltip().getElement().className = marker
					.getTooltip()
					.getElement()
					.className.replace(' selected', '');
			} else {
				const coords = marker.getLatLng();
				if (coords.lat == lat && coords.lng == lng) {
					marker.setIcon(
						this.mMarkerIconList.has(brand)
							? this.mMarkerIconList.get(brand)
							: this.mMarkerIcon
					);
					marker.getTooltip().getElement().className = marker
						.getTooltip()
						.getElement()
						.className.replace(' selected', '');
					break;
				}
			}
		}
	}

	public drawRadius(lat: number, lng: number, distance: number): void {
		if (this.mRadiusCircled != undefined) {
			this.mRadiusCircled.removeFrom(this.mMap);
			this.mRadiusCircled = null;
		}
		this.mRadiusCircled = new Circle(new LatLng(lat, lng), <CircleOptions>{
			radius: distance * 1000,
			color: '#466c55',
			fillColor: '#3b6555',
			fillOpacity: 0.2,
		});
		this.mRadiusCircled.addTo(this.mMap);
		this.setMarkerYouAreHere(lat, lng);
	}

	private onZoomEnd(event: any): void {
		this.resetMarkersList(true);
		for (let marker of this.mMarkersCoodonates) {
			this.addMarker(
				marker['data'].lat,
				marker['data'].lng,
				marker['data'].distance,
				marker['data'].id,
				marker['data'].index,
				marker['data'].brand
			);
		}
		if (this.mMarkerYouAreHere != null) {
			let userLat = this.mMarkerYouAreHere.getLatLng().lat;
			let userLng = this.mMarkerYouAreHere.getLatLng().lng;
			this.removeMarkerYouAreHere();
			this.setMarkerYouAreHere(userLat, userLng);
		}
	}

	private setMarkerYouAreHere(lat: number, lng: number): void {
		let latlng = new LatLng(lat, lng);
		//
		if (this.mMarkerYouAreHere == null) {
			this.mMarkerYouAreHere = new Marker(latlng, <MarkerOptions>{
				icon: this.mMarkerYouAreHereIcon,
			});
			this.mMarkerYouAreHere.addTo(this.mMap);
		} else {
			this.mMarkerYouAreHere.setLatLng(latlng);
		}
	}

	private removeMarkerYouAreHere(): void {
		if (this.mMarkerYouAreHere != null) {
			this.mMarkerYouAreHere.remove();
			this.mMarkerYouAreHere = null;
		}
	}

	private onMarkerCliked(event: MouseEvent): void {
		if (event.target['options'] && event.target['options'].alt) {
			this.alertParentOnMarkerClick.emit(event.target['options'].alt);
		}
	}

	private onMapClicked(): void {
		this.alertParentOnMapClick.emit();
	}

	private onTouchStart(event: any): void {
		if (event['latlng']) {
			if (this.mMouseDownTimeout != null) {
				clearTimeout(this.mMouseDownTimeout);
			}
			this.mMouseDownTimeout = setTimeout(() => {
				if (event['latlng']) {
					console.log(event['latlng']);
					let position = <LatLng>event['latlng'];
					this.alertParentOnUserChangePosition.emit(position);
				}
			}, 1500);
		}
	}

	private onTouchEnd(event: any): void {
		if (this.mMouseDownTimeout != null) {
			clearTimeout(this.mMouseDownTimeout);
		}
	}
}
