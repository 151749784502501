export class Helper {
    public static isInsideAPerimeter(checkpointLat: number, checkpointLng: number, pointLat: number, pointLng: number, km: number): boolean {
        let isInsideAPerimeter: boolean;

        isInsideAPerimeter = Helper.getDistance(checkpointLat, checkpointLng, pointLat, pointLng) <= km;
        //
        return isInsideAPerimeter;
    }

    public static getDistance(checkpointLat: number, checkpointLng: number, pointLat: number, pointLng: number): number {
        let ky; let kx; let dx; let dy: number;
        //
        ky = 40000 / 360;
        kx = Math.cos(Math.PI * pointLat / 180.0) * ky;
        dx = Math.abs(pointLng - checkpointLng) * kx;
        dy = Math.abs(pointLat - checkpointLat) * ky;
        //
        return Math.sqrt((dx * dx) + (dy * dy));
    }
}
