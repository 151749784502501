export enum LogType {
    DEBUG,
    WARNING,
    INFO,
    ERROR
}

export class LogHelper {

    public static log(message: string, type: LogType): void {
        switch (type) {
            case LogType.DEBUG:
                console.debug(message);
                break;
            case LogType.WARNING:
                console.warn(message);
                break;
            case LogType.ERROR:
                console.error(message);
                break;
            default:
                console.log(message);
                break;
        }
        if (window['android']) {
            try {
                window['android'].Log(message);
            }
            catch (err) {

            }
        }
    }
}
